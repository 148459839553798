// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { AuthProvider } from './context/AuthContext';
import { FavoritesProvider } from './context/FavoritesContext';
import Header from './components/Header';
import Footer from './components/Footer';
import BottomNav from './components/BottomNav';
import Home from './pages/Home';
import Cart from './pages/Cart';
import Lookup from './pages/Lookup';
import Login from './pages/Login';
import More from './pages/More';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

const App = () => {
  const userId = sessionStorage.getItem('customer_id');
  return (
    <AuthProvider>
      <CartProvider userId={userId}>
      <FavoritesProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
            <Route path="/lookup" element={<ProtectedRoute><Lookup /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/more" element={<ProtectedRoute><More /></ProtectedRoute>} />
          </Routes>
          <BottomNav />
          <ToastContainer
        position="bottom-center" // Center bottom position
        autoClose={5000} // Duration before auto close
        hideProgressBar={false} // Show progress bar
        newestOnTop={false} // Stack to the top
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
          <Footer />
        </Router>
        </FavoritesProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;
