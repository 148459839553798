import axios from 'axios';

export const showCart = async (userId) => {
  const token = sessionStorage.getItem('access_token'); // Retrieve the access token

  try {
    const response = await axios.get(
      `https://pos.counterbill.com/connector/api/show-cart-product/${userId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the request header
        },
      }
    );

  
return response.data.data;
  } catch (error) {
    console.error('Error fetching cart:', error);
    throw error;
  }
};
