// src/apis/SelfLogin.js

import axios from 'axios';

const fetchToken = async () => {
    const url = 'https://pos.counterbill.com/oauth/token';
    
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', '2'); // replace with your client ID
    params.append('client_secret', 'k007RVs409qNdck8M5sHInzXWfo9OKcvPJ9ikD7Q'); // replace with your client secret
    params.append('username', 'admin'); // replace with your username
    params.append('password', '123456'); // replace with your password

    try {
        const response = await axios.post(url, params.toString(), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        // Store the access token and refresh token in session storage
        sessionStorage.setItem('access_token', response.data.access_token);
        sessionStorage.setItem('refresh_token', response.data.refresh_token);
        console.log('Token retrieved and stored:', response.data.access_token);
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error; // Rethrow the error to handle it in the calling component
    }
};

export default fetchToken;
