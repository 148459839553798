import axios from 'axios';

export const storeCart = async (userId, cartEncoded) => {
  const token = sessionStorage.getItem('access_token'); // Retrieve the access token

  try {
    const response = await axios.post(
      `https://pos.counterbill.com/connector/api/store-cart-product/${userId}`,
      {
        cart_encoded: cartEncoded, // Send the encoded cart data
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the request header
          'Content-Type': 'application/json',
        },
      }
    );

    console.log(response);
    return response.data; // Return the response data from the server
  } catch (error) {
    console.error('Error storing cart:', error);
    throw error;
  }
};
