// Header.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faShoppingCart, faUser, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import SearchBar from './SearchBar';
import CustomerProfileDropdown from './CustomerProfileDropdown';
import { useCart } from '../context/CartContext'; // Import useCart

const Header = () => {
  const location = useLocation(); // Access the current location
  const [activeLink, setActiveLink] = useState(location.pathname); // Set initial state based on the current path
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [userName, setUserName] = useState(''); // State to store user's name
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility

  // Access cart state from context
  const { state } = useCart();
  
  useEffect(() => {
    setActiveLink(location.pathname); // Update activeLink on path change

    // Check session storage for user data
    const customerId = sessionStorage.getItem('customer_id');
    const firstName = sessionStorage.getItem('name');

    if (customerId && firstName) {
      setIsLoggedIn(true); // User is logged in
      setUserName(firstName); // Set user's name
    } else {
      setIsLoggedIn(false); // User is not logged in
    }
  }, [location.pathname]);

  const handleLogout = () => {
    // Clear session storage and update state
    sessionStorage.removeItem('customer_id');
    sessionStorage.removeItem('name');
   
    setIsLoggedIn(false);
    setShowDropdown(false); // Close dropdown on logout
    window.location.href="/";
  };

  return (
    <header className="flex gap-4 sticky top-0 z-10 items-center justify-between p-4 bg-[#1c9d98] text-white shadow-lg"> 
      <div className="flex items-center">
        <img src="/logo.png" alt="Logo" className="h-10" />
        <h1 className="ml-2 text-xl font-semibold whitespace-nowrap">Cake Shop</h1>
      </div>
      <SearchBar />
      <div className="hidden md:flex space-x-4">
        {[ 
          { to: "/", icon: faHome, label: "Menu" },
          { to: "/lookup", icon: faSearch, label: "Lookup" },
          { to: "/cart", icon: faShoppingCart, label: "Cart" },
        ].map(({ to, icon, label }) => (
          <Link 
            key={to}
            to={to}
            className={`flex items-center transition-all ease-in duration-300 transform 
                        ${activeLink === to ? 'bg-white text-[#1c9d98] rounded-md' : 'hover:bg-white hover:text-[#1c9d98] rounded-md'} 
                        p-2 w-24 h-12 justify-center hover:skew-y-3 hover:translate-y-1`}
          >
            <FontAwesomeIcon icon={icon} className="mr-1 text-lg relative" />
            {label}
            {to === "/cart" && state.items.length > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs px-1">
                {state.items.length}
              </span>
            )}
          </Link>
        ))}
        {isLoggedIn ? (
          <div className="relative pt-2">
            <span 
              onClick={() => setShowDropdown(!showDropdown)}
              className="bg-white  text-[#1c9d98] rounded-full w-8 h-8 flex items-center justify-center font-bold cursor-pointer"
            >
              {userName.charAt(0)} {/* Display first letter of name */}
            </span>
            {showDropdown && (
              <CustomerProfileDropdown userName={userName} onLogout={handleLogout} page={"header"} onClose={() => setShowDropdown(false)} />
            )}
          </div>
        ) : (
          // Show Login button if user is not logged in
          <Link 
            to="/login"
            className={`flex items-center transition-all ease-in duration-300 transform 
                        hover:bg-white hover:text-[#1c9d98] rounded-md p-2 w-24 h-12 justify-center`}
          >
            <FontAwesomeIcon icon={faUser} className="mr-1 text-lg" />
            Login
          </Link>
        )}
        {/* More button always visible */}
        <Link 
          to="/more"
          className={`flex items-center transition-all ease-in duration-300 transform 
                      ${activeLink === "/more" ? 'bg-white text-[#1c9d98] rounded-md' : 'hover:bg-white hover:text-[#1c9d98] rounded-md'} 
                      p-2 w-24 h-12 justify-center`}
        >
          <FontAwesomeIcon icon={faEllipsisH} className="mr-1 text-lg" />
          More
        </Link>
      </div>
    </header>
  );
};

export default Header;
