import React, { useState, useEffect } from 'react';
import CategoryList from '../components/CategoryList';
import CakeCard from '../components/CakeCard';
import axios from 'axios'; // Axios for API requests

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        // Send the GET request with the token in headers
        const response = await axios.get('https://pos.counterbill.com/connector/api/product', {
          headers: {
            'Authorization': `Bearer ${token}`, // Send the access token
            'Content-Type': 'application/json', // Additional header
            'Accept': 'application/json',
          },
        });
        const data = response.data.data;
        console.log(data);

        // Group products by categories
        const categoryProducts = {};

        // Filter and group products by categories
        data.forEach(product => {
          // Check if category is null
          if (product.category && product.category.name) {
            const categoryName = product.category.name; // Corrected to product.category.name
            if (!categoryProducts[categoryName]) {
              categoryProducts[categoryName] = [];
            }

            // Ensure product variations exist
            if (product.product_variations && product.product_variations.length > 0) {
              const firstVariation = product.product_variations[0];
              const firstProductVariation = firstVariation.variations[0];

              // Ensure firstVariation and its variations exist
              if (firstProductVariation) {
                categoryProducts[categoryName].push({
                  id: product.id,
                  name: product.name,
                  price: firstProductVariation.sell_price_inc_tax, // Check if sell_price_inc_tax exists
                  image: product.image_url || '', // Fallback to empty string if image_url is undefined
                });
              }
            }
          }
        });

        // Set the state with the fetched categories and products
        setCategories(Object.keys(categoryProducts));
        setProductsByCategory(categoryProducts);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="md:flex">
      <div className="fixed md:w-1/4 w-full z-10 md:h-screen md:p-4 bg-[#f9f9f9] border-r overflow-y-auto scrollbar">
        <CategoryList categories={categories} />
      </div>
      <div className="flex-grow md:ml-[25%] md:p-4 pt-20 overflow-y-auto min-h-screen">
        {Object.entries(productsByCategory).map(([category, cakes]) => (
          <div id={category} key={category} className="mb-8">
            <h2 className="text-2xl font-bold text-center mb-4">{category} Cakes</h2>
            <div className="flex justify-center flex-wrap gap-4">
              {cakes.map(cake => (
                <CakeCard key={cake.id} cake={cake} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
