// src/components/ProtectedRoute.js

import  { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import fetchToken from '../Apis/SelfLogin'; // Adjust the import path as needed

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn, loading, setIsLoggedIn } = useAuth();

    useEffect(() => {
        const performLogin = async () => {
            if (!isLoggedIn && !loading) {
                console.log('User is not logged in. Attempting to log in...');

                try {
                    await fetchToken(); // Attempt to fetch the token
                    setIsLoggedIn(true); // Update logged-in state
                    console.log('Login successful. User is now logged in.');
                } catch (error) {
                    console.error('Login failed:', error);
                }
            }
        };

        performLogin(); // Call the login function if user is not logged in
    }, [isLoggedIn, loading, setIsLoggedIn]);

    if (loading) {
        console.log('Loading...'); // Log loading state
        return null; // Do not render anything while loading
    }

    if (!isLoggedIn) {
        console.log('User is still not logged in after attempting to fetch token.'); // Log if still not logged in
        return null; // Do not render anything if login failed
    }

    console.log('User is logged in. Access granted.'); // Log access granted
    return children; // Render the protected content
};

export default ProtectedRoute;
