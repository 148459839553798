import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { storeCart } from '../Apis/StoreCart'; // Import the storeCart API
import { showCart } from '../Apis/ShowCart'; // Import the showCart API

const CartContext = createContext();

const initialState = {
  items: [],
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      const existingItemIndex = state.items.findIndex(item => item.id === action.payload.id);
      let updatedItems;

      if (existingItemIndex >= 0) {
        // If item exists, increment the quantity
        updatedItems = state.items.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, quantity: item.quantity + 1 };
          }
          return item;
        });
      } else {
        // If item doesn't exist, add it with quantity 1
        updatedItems = [...state.items, { ...action.payload, quantity: 1 }];
      }

      return { ...state, items: updatedItems };
    }

    case 'REMOVE_FROM_CART': {
      const existingItemIndex = state.items.findIndex(item => item.id === action.payload.id);
      let updatedItems = [...state.items];

      if (existingItemIndex >= 0) {
        const item = updatedItems[existingItemIndex];

        if (action.payload.removeAll) {
          // Remove the item completely
          updatedItems.splice(existingItemIndex, 1);
        } else if (item.quantity > 1) {
          // If quantity > 1, decrement the quantity
          updatedItems[existingItemIndex] = { ...item, quantity: item.quantity - 1 };
        } else {
          // If quantity is 1, remove the item
          updatedItems.splice(existingItemIndex, 1);
        }
      }

      return { ...state, items: updatedItems };
    }

    case 'SET_CART_ITEMS': {
      return { ...state, items: action.payload };
    }

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const userId = sessionStorage.getItem('customer_id');

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartItems = await showCart(userId);
        dispatch({ type: 'SET_CART_ITEMS', payload: cartItems });
      } catch (error) {
        console.error('Error fetching initial cart items:', error);
      }
    };

    if (userId) {
      fetchCartItems();
    }
  }, [userId]);

  useEffect(() => {
    const updateCart = async () => {
      try {
        // Encode cart items as a single JSON object
        const cartEncoded = JSON.stringify(state.items);

        // Send all cart data to the backend
        await storeCart(userId, cartEncoded);
      } catch (error) {
        console.error('Error storing cart:', error);
      }
    };

    if (state.items.length > 0) {
      updateCart();
    }
  }, [state.items, userId]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
