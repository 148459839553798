import React, { useState } from 'react';
import { useCart } from '../context/CartContext';
import { useFavorites } from '../context/FavoritesContext'; // Import the useFavorites hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faShoppingBasket, faHeart, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { storeFavProduct } from '../Apis/StoreFavProduct';
import { toast } from 'react-toastify'; // Import toast

const CakeCard = ({ cake }) => {
  const { dispatch } = useCart();
  const { favorites, setFavorites } = useFavorites(); // Access favorites and setFavorites from context
  const [showShareTooltip, setShowShareTooltip] = useState(false);
  const [showLikeTooltip, setShowLikeTooltip] = useState(false);
  const userId = sessionStorage.getItem('customer_id');
  const isFavorite = favorites.includes(cake.id.toString()); // Check if the current cake is a favorite

  const addToCart = () => {
    dispatch({ type: 'ADD_TO_CART', payload: cake });
    toast.success(`${cake.name} has been added to your cart!`); // Show toast notification
  };

  const toggleFavorite = async () => {
    try {
      const response = await storeFavProduct(cake.id, userId); // Send favorite request
      console.log(response);

      if (response.message === 'ID added successfully') {
        setFavorites((prevFavorites) => [...prevFavorites, cake.id.toString()]); // Update UI
      } else {
        setFavorites((prevFavorites) => prevFavorites.filter(id => id !== cake.id.toString())); // Remove from favorites
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: cake.name,
          text: `Check out this cake: ${cake.name}`,
          url: window.location.href,
        });
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported in your browser.');
    }
  };

  return (
    <div className="bg-white rounded-md overflow-hidden shadow-md transition-transform transform hover:scale-105 relative">
      {/* Image Section */}
      <div className="relative">
        <img src={cake.image} alt={cake.name} className="w-full h-40 object-cover" />

        {/* Like and Share Icons positioned top-right */}
        <div className="absolute top-2 right-2 flex space-x-2">
          {/* Like Button */}
          <div 
            className="relative"
            onMouseEnter={() => setShowLikeTooltip(true)}
            onMouseLeave={() => setShowLikeTooltip(false)}
          >
            <div 
              className="bg-white text-[#1c9d98] w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:scale-110 transition-transform"
              onClick={toggleFavorite}
            >
              <FontAwesomeIcon
                icon={faHeart}
                className={`text-xl ${isFavorite ? 'text-red-500' : 'text-[#1c9d98]'}`}
              />
            </div>
            {/* Tooltip for Like */}
            {showLikeTooltip && (
              <div className="absolute top-full right-0 mt-1 bg-gray-700 text-white text-xs py-1 px-2 rounded">
                {isFavorite ? 'Unlike' : 'Like'}
              </div>
            )}
          </div>

          {/* Share Button */}
          <div 
            className="relative"
            onMouseEnter={() => setShowShareTooltip(true)}
            onMouseLeave={() => setShowShareTooltip(false)}
          >
            <div 
              className="bg-white text-[#1c9d98] w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:scale-110 transition-transform"
              onClick={handleShare}
            >
              <FontAwesomeIcon icon={faShareAlt} className="text-xl" />
            </div>
            {/* Tooltip for Share */}
            {showShareTooltip && (
              <div className="absolute top-full right-0 mt-1 bg-gray-700 text-white text-xs py-1 px-2 rounded">
                Share
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4">
        <h3 className="text-lg font-bold">{cake.name}</h3>
        <p className="text-gray-700 text-xl">${cake.price}</p>
        <div className="flex space-x-4 mt-4">
          <button
            className="flex w-full items-center whitespace-nowrap border-2 border-[#1c9d98] text-[#1c9d98] px-4 py-2 rounded-lg hover:bg-[#1c9d98] hover:text-white transition duration-300"
            onClick={addToCart}
            data_id={cake.id}
          >
            <FontAwesomeIcon icon={faShoppingCart} className="mr-2 " />
            Add to Cart
          </button>
          <button className="flex w-full items-center whitespace-nowrap bg-[#1c9d98] text-white px-4 py-2 rounded-lg hover:bg-[#1a7b75] transition duration-300" data_id={cake.id}>
            <FontAwesomeIcon icon={faShoppingBasket} className="mr-2" />
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default CakeCard;
